.ElMssPgV {
  width: 100%;
}

._3hcdW153 {
  color: #82828c;
  margin-left: 5px;
}

._9RPmowv7 {
  color: red;
}

._3D9oEr8w {
  font-family: 'Bebas Neue', sans-serif;
  text-transform: uppercase;
  color: #fff;
  font-size: 24px;
  padding-top: 20px;
  padding-bottom: 0;
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

  ._3D9oEr8w ._3Lg6gA3m {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 100px;
  }

@media (max-width: 768px) {

  ._3D9oEr8w ._3Lg6gA3m {
    width: 45px;
    top: 8px;
    left: 8px;
  }
}

._3D9oEr8w ._3Lg6gA3m img {
      width: 100%;
}

._3D9oEr8w ._5TADJuPI {
    margin-right: 30px;
    padding: 0;
    color: #fff !important;
    font-weight: bold;
    text-shadow: 0px 0px 12px rgba(0, 0, 0, .33);
}

._3D9oEr8w ._5TADJuPI span {
      position: relative;
      top: 2px;
    }

._3D9oEr8w ._5TADJuPI:last-child {
  margin-right: 0;
}

.OyCWBhV7 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

._1Wz02osg {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

._1xv5qfI- {
  color: 000;
  font-size: 18px;
}

._1xv5qfI- > :first-child {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    color: #fff !important;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, .66);
  }

._1xv5qfI- :last-child {
    min-width: auto;
  }

._2vbA4WH3,
._3ILjqWPS {
  display: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  background: none;
}

@media (max-width: 768px) {
  ._2vbA4WH3,
  ._3ILjqWPS {
    display: inline-block;
    color: #fff;
    position: absolute;
    top: 12px;
    right: 12px;
  }

  .OyCWBhV7 {
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 110%;
    background: rgba(0, 0, 0, .77);
    width: 100%;
    height: 100%;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    padding: 20px;
    -webkit-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
    opacity: 0;
  }

    .OyCWBhV7 ._1Wz02osg {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start;
    }

      .OyCWBhV7 ._1Wz02osg ._5TADJuPI {
        margin-bottom: 10px;
      }
        .OyCWBhV7 ._1Wz02osg ._1xv5qfI- > a {
          padding-left: 0;
        }

  .CWQJUFmU .OyCWBhV7 {
    visibility: visible;
    left: 0;
    opacity: 1;
  }
}

.O6d4Qp8u {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center
}

.O6d4Qp8u.eqvEfy0i {
  display: block;
  position: absolute;
  top: calc(50% + 2px);
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

._3S_S9uor {
  -webkit-animation: _203YoBVP 0.75s infinite linear;
          animation: _203YoBVP 0.75s infinite linear;
}

@-webkit-keyframes _203YoBVP {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

@keyframes _203YoBVP {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}
